import React from 'react';
import {
  ConditionsCollapsible,
  FeaturedArticles,
  Hero,
  Module,
  SingleQuote,
  Structured,
  StatisticStructuredBox
} from '../../redesign';
import { CliniciansHalfAndHalf } from '../../redesign/pre-built-components';
import { Layout } from '../../redesign';

import heroContent from '../../redesign/content/reproductive-health/hero.json';
import singleStatStructuredContent from '../../redesign/content/reproductive-health/single-stat.json';
import quoteContent from '../../redesign/content/reproductive-health/quote.json';
import blogPostContent from '../../redesign/content/reproductive-health/blog-post.json';
import p2SymptomsAndConditions from '../../redesign/content/reproductive-health/p2-symptoms-and-conditions.json';
import p3ResultsDrivenWomensCare from '../../redesign/content/reproductive-health/p3-results-driven-womens-care';
import { default as SEO } from '../../components/SEO';

export default function ReproductiveHealth() {
  return (
    <Layout footerContentKey="findSupport">
      <SEO
        pageTitle="Holistic Medicine for Pregnancy | Reproductive Health | Parsley Health"
        pageDescription="Turn to Parsley Health for better approaches to reproductive health. Learn about functional medicine for fertility issues and holistic medicine for your pregnancy"
      />
      <Module>
        <Hero {...heroContent} />
      </Module>
      <Module marginTop="lg">
        <StatisticStructuredBox {...singleStatStructuredContent} />
      </Module>
      <Module>
        <ConditionsCollapsible {...p2SymptomsAndConditions} />
      </Module>
      <Module>
        <Structured {...p3ResultsDrivenWomensCare} />
      </Module>
      <Module>
        <SingleQuote {...quoteContent} />
      </Module>
      <Module>
        <CliniciansHalfAndHalf
          ctaTrackingLabel="Meet Our Clinicians button on the Reproductive Health page - Stress-free online holistic care module"
          eyebrow="We make getting pregnant easier"
        />
      </Module>
      <Module>
        <FeaturedArticles {...blogPostContent} />
      </Module>
    </Layout>
  );
}
