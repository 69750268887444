import React from 'react';
import { IllustrativeIcon } from '../../atoms/icons/illustrative-icon/illustrative-icon';
import { StructuredProps } from '../../types';

const Data: StructuredProps = {
  alignment: 'center',
  heading: "Results-driven women's care",
  items: [
    {
      id: 'hormonal-screening',
      heading: 'We analyze your whole health.',
      description:
        'Our world-class clinicians take the time to get to know you, in person or virtually, to gain a deeper understanding of your reproductive health.',
      illustration: <IllustrativeIcon color="sage" name="personal" />
    },
    {
      id: 'whole-body-approach',
      heading: 'Deeper diagnostics than other doctors.',
      description:
        'Based on your concerns, we recommend advanced testing, then help you understand your results. Your Care Team creates a personalized plan for your reproductive health goals.',
      illustration: <IllustrativeIcon color="sage" name="testing" />
    },
    {
      id: 'personalized-ongoing-support',
      heading: 'Personalized care meets ongoing support.',
      description:
        'Your Care Team offers you recommendations to optimize your fertility naturally, as well as nutritional and lifestyle insights.',
      illustration: <IllustrativeIcon color="sage" name="teamCollab" />
    }
  ]
};

export default Data;
